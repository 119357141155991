import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchProducts = createAsyncThunk(
  "product/fetchProducts",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get("products/products_list", {
        withCredentials: true,
      });

      if (data.status_code === 0) {
        return fulfillWithValue(data.products);
      } else {
        throw new Error(data.message || "Error fetching products");
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchProductById = createAsyncThunk(
  "product/fetchProductById",
  async (productId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/products/product_details/${productId}`, {
        withCredentials: true,
      });

      if (data.status_code === 0) {
        if (!data.productDetails || !data.productDetails.product_id) {
          throw new Error("Product ID not found in response data");
        }
        return fulfillWithValue(data.productDetails);
      } else {
        throw new Error(data.message || "Failed to fetch product details");
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchTopProducts = createAsyncThunk(
  "product/fetchTopProducts",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get("/products/top_products", {
        withCredentials: true,
      });

      if (data.status_code === 0) {
        return fulfillWithValue(data.topProducts);
      } else {
        throw new Error(data.message || "Error fetching top products");
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const searchProducts = createAsyncThunk(
  "products/searchProducts",
  async (searchTerm, { rejectWithValue }) => {
    try {
      const response = await api.get(`/search?searchTerm=${searchTerm}`);
      if (response.data.status_code === 0) {
        return response.data.data.products;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue("An error occurred while searching.");
    }
  }
);

export const filterProductByCategory = createAsyncThunk(
  "product/filterProductByCategory",
  async (categoryId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/categories/all_categories`, {
        withCredentials: true,
      });

      if (data.status_code === 0) {
        const category = data.categories.find(
          (cat) => cat.category_id === categoryId
        );
        if (!category || !category.products) {
          throw new Error("Category or products not found in response data");
        }
        return fulfillWithValue(category.products);
      } else {
        throw new Error(data.message || "Error fetching category products");
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const productReducer = createSlice({
  name: "product",
  initialState: {
    products: [],
    topProducts: [],
    searchResults: [],
    filteredProducts: [],
    productDetails: {},
    loader: false,
    errorMessage: "",
    resultErrorMessage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Products
      .addCase(fetchProducts.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loader = false;
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.error.message;
      })
      .addCase(fetchProductById.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.loader = false;
        state.productDetails = action.payload;
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.payload;
      })
      .addCase(fetchTopProducts.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchTopProducts.fulfilled, (state, action) => {
        state.loader = false;
        state.topProducts = action.payload;
      })
      .addCase(fetchTopProducts.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.payload;
      })

      // Search Products
      .addCase(searchProducts.pending, (state) => {
        state.loader = true;
      })
      .addCase(searchProducts.fulfilled, (state, action) => {
        state.loader = false;
        state.searchResults = action.payload;
        state.resultErrorMessage = null;
      })
      .addCase(searchProducts.rejected, (state, action) => {
        state.resultErrorMessage = action.payload;
        state.searchResults = [];
      })
      // Filter Products by Category
      .addCase(filterProductByCategory.pending, (state) => {
        state.loader = true;
      })
      .addCase(filterProductByCategory.fulfilled, (state, action) => {
        state.loader = false;
        state.filteredProducts = action.payload;
      })
      .addCase(filterProductByCategory.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.payload;
      });
  },
});

export default productReducer.reducer;
