import { Link } from "react-router-dom";

const ProductCardRating = ({ productImage, productName, productRating }) => {
  return (
    <div className="p-1 bg-white border rounded-lg border-borderLine">
      <Link>
        <img
          src={productImage}
          alt="samsung Galaxy Watch"
          className="w-full h-[180px] object-cover rounded-md hover:scale-95"
        />
        <div className="px-1 py-2">
          <p className="text-sm font-normal capitalize truncate font-baskerVille text-black/50 hover:text-black">
            {productName}
          </p>
          <span className="pt-1 text-sm font-bold">Store rating</span>
          <span className="text-sm font-bold text-blue ms-2">
            {productRating}
          </span>
        </div>
      </Link>
    </div>
  );
};

export default ProductCardRating;
