// import { Link } from "react-router-dom";
// import { formatPrice } from "../utilities/formatPrice";

// const ProductCard = ({
//   productId,
//   productImage,
//   productName,
//   productPrice,
//   discountedPrice,
//   itemLeft,
//   lazyLoad = false,
// }) => {
//   return (
//     <div className="p-1 transition-shadow duration-200 bg-white border rounded-lg border-borderLine hover:shadow-md">
//       <Link to={`/productDetails/${productId}`}>
//         <div>
//           <img
//             src={productImage}
//             alt={productName}
//             className="w-full h-[150px] sm:h-[180px] object-cover rounded-md hover:scale-95 transition-transform duration-200"
//             loading={lazyLoad ? "lazy" : "eager"}
//           />
//           <div className="px-1 py-2">
//             <p className="text-sm font-normal capitalize truncate transition-colors duration-200 font-baskerVille text-black/50 hover:text-black">
//               {productName}
//             </p>

//             <p className="text-lg font-medium">{`₦${formatPrice(
//               discountedPrice
//             )}`}</p>

//             <div className="pt-1 space-y-1 sm:space-y-0 sm:flex sm:items-center sm:justify-between">
//               {discountedPrice && (
//                 <p className="text-xs font-bold line-through text-black/30">{`₦${formatPrice(
//                   productPrice
//                 )}`}</p>
//               )}
//               {itemLeft > 0 ? (
//                 <p className="text-sm font-bold text-ashColor">
//                   Items Left: {itemLeft}
//                 </p>
//               ) : (
//                 <p className="text-sm font-bold text-orange_red">
//                   Out of Stock
//                 </p>
//               )}
//             </div>
//           </div>
//         </div>
//       </Link>
//     </div>
//   );
// };

// export default ProductCard;

import { Link } from "react-router-dom";
import { formatPrice } from "../utilities/formatPrice";

const ProductCard = ({
  productId,
  productImage,
  productName,
  productPrice,
  discountedPrice,
  itemLeft,
  lazyLoad = false,
}) => {
  return (
    <div className="p-1 transition-shadow duration-200 bg-white border rounded-lg border-borderLine hover:shadow-md">
      <Link to={`/productDetails/${productId}`}>
        <div>
          <img
            src={productImage}
            alt={productName}
            className="w-full h-[150px] sm:h-[180px] object-cover rounded-md hover:scale-95 transition-transform duration-200"
            loading={lazyLoad ? "lazy" : "eager"}
          />
          <div className="px-1 py-2">
            <p className="text-sm font-normal capitalize truncate transition-colors duration-200 font-baskerVille text-black/50 hover:text-black">
              {productName}
            </p>

            <p className="text-lg font-medium">
              {discountedPrice
                ? `₦${formatPrice(discountedPrice)}`
                : `₦${formatPrice(productPrice)}`}
            </p>

            <div className="pt-1 space-y-1 sm:space-y-0 sm:flex sm:items-center sm:justify-between">
              {discountedPrice && (
                <p className="text-xs font-bold line-through text-black/30">{`₦${formatPrice(
                  productPrice
                )}`}</p>
              )}
              {itemLeft > 0 ? (
                <p className="text-sm font-bold text-ashColor">
                  Items Left: {itemLeft}
                </p>
              ) : (
                <p className="text-sm font-bold text-orange_red">
                  Out of Stock
                </p>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
