import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchCartItems,
  updateItemQuantity,
  removeFromCart,
  messageClear,
  setSubtotal,
} from "../store/reducers/cartReducer";
import { generateRandomColor } from "../utilities/generateRandomColor";
import TopBar from "../components/TopBar";
import { transformGoogleDriveLink } from "../utilities/transformGoogleDriveLink";
import { formatPrice } from "../utilities/formatPrice";
import toast from "react-hot-toast";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cartItems, errorMessage, successMessage, subtotal } = useSelector(
    (state) => state.cart
  );
  const { userInfo } = useSelector((state) => state.auth);

  const [localQuantities, setLocalQuantities] = useState({});

  useEffect(() => {
    const initialQuantities = {};
    cartItems.forEach((item) => {
      initialQuantities[item.cart_id] = item.quantity;
    });
    setLocalQuantities(initialQuantities);
  }, [cartItems]);

  const updateQuantity = (itemId, newQuantity) => {
    if (newQuantity >= 1 && newQuantity <= 20) {
      setLocalQuantities((prev) => ({
        ...prev,
        [itemId]: newQuantity,
      }));
      dispatch(
        updateItemQuantity({
          user_id: userInfo.userId,
          itemId,
          quantity: newQuantity,
        })
      );
    }
  };

  const handleRemoveItem = (itemId) => {
    dispatch(
      removeFromCart({
        user_id: userInfo.userId,
        itemId,
      })
    ).then(() => {
      dispatch(fetchCartItems(userInfo.userId));
    });
  };

  useEffect(() => {
    const calculatedSubtotal = cartItems.reduce((total, item) => {
      const price = parseFloat(
        String(item.discounted_price).replace(/,/g, "") || "0"
      );
      const quantity = localQuantities[item.cart_id] || item.quantity || 0;
      return total + price * quantity;
    }, 0);

    dispatch(setSubtotal(calculatedSubtotal));
  }, [cartItems, localQuantities, dispatch]);

  const formattedSubtotal = (subtotal || 0).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage, dispatch]);

  const productPictureUrls = cartItems.map((item) =>
    item.product_picture
      ? transformGoogleDriveLink(item.product_picture)
      : `https://via.placeholder.com/640x480.png/${generateRandomColor()}?text=${encodeURIComponent(
          item.product_name
        )}`
  );

  const handleCheckout = (e) => {
    if (cartItems.length === 0 || subtotal === 0) {
      e.preventDefault();
      toast.error("Can't proceed to checkout, your cart is empty.");
    } else {
      navigate("/checkout");
    }
  };

  return (
    <div className="bg-[#F5F5F5] min-h-screen">
      <TopBar />
      <div className="grid grid-cols-1 gap-4 p-8 md:grid-cols-12 font-nunito">
        <main className="grid gap-4 md:col-span-8">
          <div className="p-6 bg-white rounded">
            <h2 className="mb-2 text-xl font-bold font-baskerVille">
              Shopping Cart
            </h2>
            <p>Items: {cartItems.length}</p>
          </div>

          <div className="px-6 py-6 bg-white rounded">
            {cartItems.map((item, index) => (
              <div key={index}>
                <div className="flex items-center justify-center mb-8 md:justify-between">
                  <div className="flex flex-col items-center border rounded md:flex-row md:border-0">
                    <img
                      src={productPictureUrls[index]}
                      alt={"product"}
                      className="md:w-32 md:h-32 h-[200px] w-full object-cover rounded"
                    />
                    <div className="p-2 md:ml-4">
                      <p className="mb-2 text-lg font-semibold">
                        {item.product_name}
                      </p>
                      <Link
                        to={"#"}
                        className="inline-block mb-4 underline text-blue"
                      >
                        {item.storeName}
                      </Link>
                      <div className="flex items-center gap-x-4">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            updateQuantity(
                              item.cart_id,
                              localQuantities[item.cart_id] - 1
                            );
                          }}
                          className="px-2 py-1 text-gray-700 bg-gray-200 rounded-l"
                          disabled={localQuantities[item.cart_id] <= 1}
                        >
                          -
                        </button>
                        <input
                          type="number"
                          min="1"
                          max="20"
                          value={localQuantities[item.cart_id]}
                          className="p-2 rounded md:w-24 md:p-3 bg-grayColor bg-opacity-20"
                          readOnly
                        />
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            updateQuantity(
                              item.cart_id,
                              localQuantities[item.cart_id] + 1
                            );
                          }}
                          className="px-2 py-1 text-gray-700 bg-gray-200 rounded-r"
                          disabled={localQuantities[item.cart_id] >= 20}
                        >
                          +
                        </button>
                        <p className="mr-2 text-sm md:text-lg md:font-semibold">
                          {`₦${formatPrice(item.discounted_price)}`}
                        </p>
                        <div className="block px-4 py-3 rounded-full bg-red/10 md:hidden">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveItem(item.cart_id);
                            }}
                          >
                            {/* SVG Icon for delete */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                d="M3.6675 1.467V0H11.0025V1.467H14.67V2.934H13.203V13.9365C13.203 14.3416 12.8746 14.67 12.4695 14.67H2.2005C1.7954 14.67 1.467 14.3416 1.467 13.9365V2.934H0V1.467H3.6675ZM2.934 2.934V13.203H11.736V2.934H2.934ZM5.1345 5.1345H6.6015V11.0025H5.1345V5.1345ZM8.0685 5.1345H9.5355V11.0025H8.0685V5.1345Z"
                                fill="#F9533C"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hidden px-6 py-5 rounded-full bg-red/10 md:block">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleRemoveItem(item.cart_id);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <path
                          d="M3.6675 1.467V0H11.0025V1.467H14.67V2.934H13.203V13.9365C13.203 14.3416 12.8746 14.67 12.4695 14.67H2.2005C1.7954 14.67 1.467 14.3416 1.467 13.9365V2.934H0V1.467H3.6675ZM2.934 2.934V13.203H11.736V2.934H2.934ZM5.1345 5.1345H6.6015V11.0025H5.1345V5.1345ZM8.0685 5.1345H9.5355V11.0025H8.0685V5.1345Z"
                          fill="#F9533C"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </main>

        <aside className="flex-1 p-8 bg-white rounded md:col-span-4 h-max">
          <h2 className="mb-4 text-xl font-bold font-baskerVille">Summary</h2>

          <div className="flex items-center justify-between mb-4 font-semibold">
            <span>Subtotal:</span>
            <p className="text-lg">{formattedSubtotal}</p>
          </div>

          <div className="flex items-center justify-between mb-4 font-semibold">
            <span>Shipping fee:</span>
            <span className="text-sm md:text-lg text-ashColor">
              Proceed to checkout
            </span>
          </div>

          <div className="flex items-center justify-between py-4 mb-4 font-semibold border-t">
            <span className="text-blue">Total Price:</span>
            <p className="text-2xl text-blue">{formattedSubtotal}</p>
          </div>

          <button
            onClick={handleCheckout}
            className="block w-full px-2 py-3 text-center text-white rounded bg-blue"
          >
            Proceed to Checkout
          </button>
        </aside>
      </div>
    </div>
  );
};

export default Cart;
