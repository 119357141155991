import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ProductCard from "../components/productCard";
import { generateRandomColor } from "../utilities/generateRandomColor";
import { transformGoogleDriveLink } from "../utilities/transformGoogleDriveLink";
import { calculateDiscountedPrice } from "../utilities/discountPrice";
import TopBar from "../components/TopBar";

const SearchResults = () => {
  const { searchResults, resultErrorMessage } = useSelector(
    (state) => state.product
  );
  const location = useLocation();
  const searchTerm = new URLSearchParams(location.search).get("query");

  useEffect(() => {}, [searchResults]);

  return (
    <div>
      <TopBar />
      <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">
          Search Results for "{searchTerm}"
        </h2>
        {resultErrorMessage ? (
          <p className="text-red-500">{resultErrorMessage}</p>
        ) : searchResults.length > 0 ? (
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
            {searchResults.map((product) => {
              const productPictureUrl = product.product_picture?.picture1 || "";
              const transformedUrl = productPictureUrl
                ? transformGoogleDriveLink(productPictureUrl)
                : "";

              const originalPrice = product.product_price
                ? parseFloat(product.product_price.replace(/,/g, ""))
                : 0;
              const discountPercentage = parseFloat(
                product.product_discount_percentage
              );
              const discountedPrice = calculateDiscountedPrice(
                originalPrice,
                discountPercentage
              );

              return (
                <ProductCard
                  key={product.product_id}
                  productId={product.product_id}
                  productImage={
                    transformedUrl ||
                    `https://via.placeholder.com/640x480.png/${generateRandomColor()}?text=${
                      product.product_name
                    }`
                  }
                  productName={product.product_name}
                  productPrice={discountedPrice}
                  originalPrice={originalPrice}
                  itemLeft={product.product_quantity}
                />
              );
            })}
          </div>
        ) : (
          <p>No products found for "{searchTerm}".</p>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
