import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  fetchTopMerchants,
} from "../store/reducers/homeReducer";
import {
  fetchTopProducts,
  fetchProducts,
} from "../store/reducers/productReducer";
import TopBar from "../components/TopBar";
import StoreCategories from "../components/storeCategories";
import kiddies from "../assets/images/productImage/kiddies.png";
import Headsets from "../assets/images/productImage/headsetStore.png";
import vegetableStore from "../assets/images/productImage/vegetable.png";
import chicken from "../assets/images/productImage/chicken.png";
import ProductCard from "../components/productCard";
import advertiseImage from "../assets/images/background/illustration.png";
import ProductCardRating from "../components/ProductCardRating";
import { useState, useEffect } from "react";
import { FaTshirt, FaCouch, FaBook, FaFootballBall } from "react-icons/fa";
import Slider from "../components/Slider";
import { generateRandomColor } from "../utilities/generateRandomColor";
import { transformGoogleDriveLink } from "../utilities/transformGoogleDriveLink";
import { calculateDiscountedPrice } from "../utilities/discountPrice";
import { filterProductByCategory } from "../store/reducers/productReducer";
import { ClipLoader } from "react-spinners";

const Home = () => {
  const dispatch = useDispatch();
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const {
    categories,
    topMerchants,
    loader: categoryLoader,
    errorMessage: categoryErrorMessage,
  } = useSelector((state) => state.home);
  const {
    topProducts,
    loader: productLoader,
    errorMessage: productErrorMessage,
  } = useSelector((state) => state.product);

  const { products, loader, errorMessage } = useSelector(
    (state) => state.product
  );

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchTopProducts());
    dispatch(fetchProducts());
    dispatch(fetchTopMerchants());
  }, [dispatch]);

  if (categoryLoader || productLoader) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ClipLoader color="#127EC8" loading={true} size={50} />
      </div>
    );
  }

  if (!categories || !Array.isArray(categories)) {
    return <p>Loading categories...</p>;
  }

  const categoryIcons = {
    Clothings: <FaTshirt size={24} />,
    "Home and Furniture": <FaCouch size={24} />,
    "Books and Media": <FaBook size={24} />,
    "Sports and Outdoors": <FaFootballBall size={24} />,
  };

  const handleCategoryClick = (categoryId) => {
    dispatch(filterProductByCategory(categoryId));
  };

  return (
    <div className="flex flex-col min-h-screen bg-fullbg">
      <TopBar />
      <div className="mx-4 my-6 sm:mx-10">
        {/* Banner and Store Categories Start */}
        <div className="grid gap-4 sm:grid-cols-12">
          <div className="col-span-12 md:col-span-3">
            <div className="flex flex-col h-full bg-white border rounded-lg border-borderLine">
              <div className="px-5 py-5 border-b border-borderLine">
                <span className="text-sm font-bold font-baskerVille">
                  Store Categories.
                </span>
              </div>
              <div className="flex-grow px-2 py-3">
                {categories.length > 0 ? (
                  categories.map((category) => (
                    <div
                      key={category.category_id}
                      onClick={() => handleCategoryClick(category.category_id)}
                    >
                      <StoreCategories
                        key={category.category_id}
                        storeName={category.category_name}
                        storeLink={`/storeCategories/${category.category_id}`}
                      />
                    </div>
                  ))
                ) : (
                  <p>No categories available</p>
                )}
              </div>
            </div>
          </div>

          <div className="w-full col-span-12 overflow-hidden rounded-lg md:col-span-5">
            <Slider />
          </div>

          <div className="flex flex-col justify-end w-full min-h-[500px] h-full col-span-12 bg-no-repeat bg-cover md:col-span-4 bg-bakerImage">
            <div className="mx-3 mb-2">
              <p className="pb-4 text-lg font-bold text-center text-white capitalize font-baskerVille">
                Extend Your Business reach by Selling on Zippyworld's
                Marketplace
              </p>
              <p className="text-sm font-medium text-center text-white mx-7">
                Join the growing trend of virtual stores and grow your business
                reach
              </p>

              <div className="text-center py-7">
                <a
                  href="https://merchant.zworld.ng/login"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white rounded-[5px] py-3 px-[30px] text-black text-sm font-normal hover:font-semibold hover:scale-105 inline-block text-center"
                >
                  Start Selling
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Top Products Start */}

        <div className="pt-14">
          <p className="pb-2 text-base font-bold border-b border-borderLine font-baskerVille">
            Top Products
          </p>
          <div
            className={`grid gap-3 py-5 ${
              topProducts.length === 4
                ? "grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
                : topProducts.length === 5
                ? "grid-cols-2 md:grid-cols-3 lg:grid-cols-5"
                : "grid-cols-2 sm:grid-cols-5"
            }`}
          >
            {Array.isArray(topProducts) && topProducts.length > 0 ? (
              topProducts.map((product) => {
                const productPictureUrl =
                  product.productPicture?.picture1 || "";
                const transformedUrl = productPictureUrl
                  ? transformGoogleDriveLink(productPictureUrl)
                  : "";

                const originalPrice = product.productPrice
                  ? parseFloat(product.productPrice.replace(/,/g, ""))
                  : 0;
                const discountPercentage = parseFloat(
                  product.productDiscountPercentage
                );

                const discountedPrice = !isNaN(discountPercentage)
                  ? calculateDiscountedPrice(originalPrice, discountPercentage)
                  : null;

                return (
                  <ProductCard
                    key={product.productId}
                    productId={product.productId}
                    productImage={
                      transformedUrl ||
                      `https://via.placeholder.com/640x480.png/${generateRandomColor()}?text=${
                        product.productName
                      }`
                    }
                    productName={product.shortProductName}
                    productPrice={originalPrice}
                    discountedPrice={discountedPrice}
                    itemLeft={product.productQuantity}
                    lazyLoad={true}
                  />
                );
              })
            ) : (
              <p>No top products available</p>
            )}
          </div>
        </div>

        {/* Top Product End */}

        {/* Top Stores Start */}
        <div className="pt-10">
          <div className="pb-2 border-b border-borderLine">
            <span className="text-base font-bold font-baskerVille">
              Top Stores
            </span>
            <span className="text-base font-medium text-blue/50 ms-3 hover:text-blue">
              <Link>[ view all stores ]</Link>
            </span>
          </div>
          <div className="grid grid-cols-2 gap-3 py-5 sm:grid-cols-3 md:grid-cols-4">
            {Array.isArray(topMerchants) && topMerchants.length > 0 ? (
              topMerchants.map((merchant) => {
                const rating =
                  merchant.averageRating === "This merchant has no ratings"
                    ? 0.0
                    : parseFloat(merchant.averageRating).toFixed(1);

                return (
                  <ProductCardRating
                    key={merchant.merchantId}
                    productImage={
                      transformGoogleDriveLink(merchant.storeBannerImage) ||
                      `https://via.placeholder.com/640x480.png/${generateRandomColor()}?text=${
                        merchant.name
                      }`
                    }
                    productName={merchant.storeName || "Unknown Store Name"}
                    productRating={rating}
                  />
                );
              })
            ) : (
              <p>No top stores available</p>
            )}
          </div>
        </div>

        {/* Top Stores End */}

        {/* Advertisement Start  */}
        <div className="bg-[#FF7518]  my-3 py-5 bg-blend-multiply rounded-lg bg-backgroundWhite">
          <div className="px-5 py-3 text-white sm:flex sm:justify-between sm:px-24">
            <div className="py-8 sm:py-0 sm:my-auto">
              <p className="text-lg font-bold text-center font-baskerVille sm:text-start">
                Advertise with us
              </p>
              <p className="pt-1 pb-5 text-base font-normal text-center sm:me-20 sm:text-start">
                Reach a vast audience of potential customers. Our user-friendly
                platform offers affordable advertising options and a secure
                payment system.
              </p>
              <div className="text-center sm:text-start">
                <button className="bg-white text-[#FF7518] px-7 py-3 gap-3 rounded-md">
                  Place an advert
                </button>
              </div>
            </div>
            <div>
              <img
                src={advertiseImage}
                alt="advertise with us"
                className="w-full"
              />
            </div>
          </div>
        </div>
        {/* Advertisement End  */}
        {/* Recommend Product Start  */}
        <div className="pb-10 pt-11">
          <p className="pb-2 text-base font-bold border-b border-borderLine font-baskerVille">
            Recommended for you
          </p>

          <div className="grid grid-cols-2 gap-3 py-5 sm:grid-cols-3 lg:grid-cols-5">
            {Array.isArray(products) && products.length > 0 ? (
              products.map((product) => {
                const productPictureUrl =
                  product.product_picture?.picture1 || "";
                const transformedUrl = productPictureUrl
                  ? transformGoogleDriveLink(productPictureUrl)
                  : "";

                const originalPrice = product.product_price
                  ? parseFloat(product.product_price.replace(/,/g, ""))
                  : 0;
                const discountPercentage = parseFloat(
                  product.product_discount_percentage
                );

                const discountedPrice = !isNaN(discountPercentage)
                  ? calculateDiscountedPrice(originalPrice, discountPercentage)
                  : null;

                return (
                  <ProductCard
                    key={product.product_id}
                    productId={product.product_id}
                    productImage={
                      transformedUrl ||
                      `https://via.placeholder.com/640x480.png/${generateRandomColor()}?text=${
                        product.productName
                      }`
                    }
                    productName={product.short_product_name}
                    productPrice={originalPrice}
                    discountedPrice={discountedPrice}
                    itemLeft={product.product_quantity}
                    lazyLoad={true}
                  />
                );
              })
            ) : (
              <p>No recommended products available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
