import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import TopBar from "../components/TopBar";
import Button from "../components/Button";
import ProductCard from "../components/productCard";
import {
  fetchProductById,
  fetchProducts,
} from "../store/reducers/productReducer";
import { generateRandomColor } from "../utilities/generateRandomColor";
import CustomerReviews from "../components/customerReviews";
import ProductRating from "../components/productRating";
import { addToCart, messageClear } from "./../store/reducers/cartReducer";
import toast from "react-hot-toast";
import { transformGoogleDriveLink } from "../utilities/transformGoogleDriveLink";
import { calculateDiscountedPrice } from "../utilities/discountPrice";
import { formatPrice } from "../utilities/formatPrice";
import { FaInstagram } from "react-icons/fa";
import { fetchAverageRating } from "../store/reducers/reviewReducer";
import { filterProductByCategory } from "../store/reducers/productReducer";

const ProductDetails = () => {
  const { productId } = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.product.productDetails);
  const averageRating = useSelector((state) => state.reviews.averageRating);
  const { errorMessage, successMessage } = useSelector((state) => state.cart);
  const filteredProducts = useSelector(
    (state) => state.product.filteredProducts
  );
  const products = useSelector((state) => state.product.products);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    dispatch(fetchProductById(productId));
  }, [dispatch, productId]);
  useEffect(() => {
    if (productDetails?.category_id) {
      dispatch(filterProductByCategory(productDetails.category_id));
    }
  }, [dispatch, productDetails?.category_id]);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage, dispatch]);

  if (!productDetails) {
    return <div>Loading...</div>;
  }

  const addCart = () => {
    if (userInfo) {
      dispatch(
        addToCart({
          product_id: productId,
          quantity,
          user_id: userInfo.userId,
          merchant_id: productDetails.merchant_id,
        })
      );
    } else {
      toast.error("Please login to purchase an item");
      navigate("/login");
    }
  };

  const originalPrice = productDetails.product_price
    ? parseFloat(productDetails.product_price.replace(/,/g, ""))
    : 0;

  const discountPercentage = productDetails.product_discount_percentage
    ? parseFloat(productDetails.product_discount_percentage)
    : NaN;

  const discountedPrice = !isNaN(discountPercentage)
    ? calculateDiscountedPrice(originalPrice, discountPercentage)
    : originalPrice;

  const productPictureUrl = productDetails.product_picture
    ? transformGoogleDriveLink(productDetails.product_picture)
    : `https://via.placeholder.com/640x480.png/${generateRandomColor()}?text=${
        productDetails.product_name
      }`;

  return (
    <div className="bg-fullbg">
      <TopBar />
      <div className="py-10 mx-4 sm:mx-10">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-4">
            <div>
              <img
                src={productPictureUrl}
                alt={productDetails.product_name}
                className="object-cover rounded-lg aspect-square"
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-5">
            <p className="pb-8 text-3xl font-bold font-baskerville">
              {productDetails.product_name}
            </p>

            <div>
              <p className="text-base font-bold">Store name:</p>
              {productDetails.storeName ? (
                <Link to="/stores">
                  <p className="text-sm underline text-blue">
                    {productDetails.storeName}
                  </p>
                </Link>
              ) : (
                <p className="text-sm text-black">Unknown Store</p>
              )}
            </div>

            {/* Product rating */}
            <div className="py-3">
              <p className="text-base font-bold">Product Rating:</p>
              {averageRating ? (
                <span className="text-sm font-medium">
                  {`${averageRating} out of 10`}
                </span>
              ) : (
                <span className="text-sm text-black/50">
                  No rating available
                </span>
              )}
            </div>
            <div>
              <p className="text-base font-bold">Product description:</p>
              <span className="text-sm text-justify">
                {productDetails.product_description}
              </span>
            </div>
          </div>
          <div className="col-span-12 md:col-span-3">
            <div className="px-3 py-5 bg-white border rounded-lg border-borderLine">
              <p className="text-base font-bold font-baskerville">Price:</p>
              <div className="flex items-center">
                {isNaN(discountPercentage) ? (
                  <span className="text-xl font-bold text-blue">
                    {`₦${formatPrice(originalPrice)}`}{" "}
                  </span>
                ) : (
                  <>
                    <span className="text-xl font-bold text-blue">
                      {`₦${formatPrice(discountedPrice)}`}{" "}
                    </span>
                    <p className="mx-2 text-xs font-bold line-through text-black/30">
                      {`₦${formatPrice(originalPrice)}`}
                    </p>
                    <span className="px-2 text-sm text-[#dc2626] bg-[#fee2e2] rounded">
                      {`-${discountPercentage}%`}
                    </span>
                  </>
                )}
              </div>
              <div className="pt-7">
                <p className="pb-1 text-sm">Quantity</p>
                <div>
                  <input
                    className="bg-[#C4C4C4]/20 text-gray-700 py-3 px-4 rounded-[5px] w-full text-sm font-normal outline-none"
                    value={quantity}
                    onChange={(e) => setQuantity(Number(e.target.value))}
                    type="number"
                    min="1"
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 pt-4 pb-8 gap-x-2">
                <div className="col-span-12 rounded">
                  <Button
                    onClick={addCart}
                    buttonType={""}
                    fullWidth
                    buttonName={
                      <div className="flex items-center justify-center gap-x-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                          >
                            {/* SVG path here */}
                          </svg>
                        </span>
                        <span>Add to cart</span>
                      </div>
                    }
                  />
                </div>
              </div>
              <hr className="space-x-4 border-dotted border-ashColor/50 border-t-1" />

              <div className="pb-3 text-sm text-center pt-7">
                <span>
                  If you have any problem with this product, please contact us{" "}
                  <a
                    href="https://www.instagram.com/zippyworldnet/"
                    className="font-semibold text-blue"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @zippyworld.net
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-4 pt-12">
          <ProductRating />

          <CustomerReviews productId={productId} />
        </div>

        <div className="pt-8">
          <p className="pb-4 text-lg font-medium font-baskerVille">
            Similar Products
          </p>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product) => {
                let productPicture = product.product_picture;
                if (typeof productPicture === "string") {
                  try {
                    productPicture = JSON.parse(productPicture);
                  } catch (error) {
                    console.error("Error parsing product_picture:", error);
                    productPicture = null;
                  }
                }

                const productPictureUrl = productPicture?.picture1 || "";
                const transformedUrl = productPictureUrl
                  ? transformGoogleDriveLink(productPictureUrl)
                  : `https://via.placeholder.com/640x480.png/${generateRandomColor()}?text=${
                      product.product_name
                    }`;

                const originalPrice = product.product_price
                  ? parseFloat(product.product_price.replace(/,/g, ""))
                  : 0;
                const discountPercentage = parseFloat(
                  product.product_discount_percentage || 0
                );
                const discountedPrice = calculateDiscountedPrice(
                  originalPrice,
                  discountPercentage
                );

                return (
                  <ProductCard
                    key={product.product_id}
                    productId={product.product_id}
                    productImage={transformedUrl}
                    productName={product.product_name}
                    productPrice={discountedPrice}
                    originalPrice={originalPrice}
                    itemLeft={product.product_quantity}
                    lazyLoad={true}
                  />
                );
              })
            ) : (
              <p className="font-nunito">No similar products</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
