// import React from "react";
// import { Link } from "react-router-dom";
// import Button from "../components/Button";
// import checkIcon from "../../src/assets/images/success.gif";

// const ThankYou = () => {
//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
//       <div className="p-8 text-center bg-white w-80">
//         <img
//           src={checkIcon}
//           alt="Purchase Success Icon"
//           className="w-16 h-16"
//         />
//         <h1 className="mb-4 text-4xl font-bold text-green-600">Thank You!</h1>
//         <p className="mb-8 text-lg text-gray-700">
//           You have successfully purchased an item
//         </p>
//       </div>
//     </div>
//   );
// };

// export default ThankYou;

// import React from "react";
// import checkIcon from "../../src/assets/images/success.gif";

// const ThankYou = () => {
//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
//       <div className="flex flex-col items-center p-8 text-center bg-white w-80">
//         <img
//           src={checkIcon}
//           alt="Purchase Success Icon"
//           className="w-16 h-16 mb-4"
//         />
//         <h1 className="mb-4 text-4xl font-bold text-green-600">Thank You!</h1>
//         <p className="mb-8 text-lg text-gray-700">
//           You have successfully purchased an item
//         </p>
//       </div>
//     </div>
//   );
// };

// export default ThankYou;

// import React from "react";
// import checkIcon from "../../src/assets/images/success.gif";

// const ThankYou = ({ isOpen, closeModal }) => {
//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="flex flex-col items-center p-8 text-center bg-white rounded-lg shadow-lg w-80">
//         <img
//           src={checkIcon}
//           alt="Purchase Success Icon"
//           className="w-16 h-16 mb-4"
//         />
//         <h1 className="mb-4 text-4xl font-bold text-green-600">Thank You!</h1>
//         <p className="mb-8 text-lg text-gray-700">
//           You have successfully purchased an item
//         </p>
//         <button
//           onClick={closeModal}
//           className="px-4 py-2 text-white bg-green-600 rounded hover:bg-green-700"
//         >
//           Close
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ThankYou;

import React from "react";
import checkIcon from "../../src/assets/images/success.gif";

const ThankYou = ({ isOpen, closeModal }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="flex flex-col items-center w-full max-w-sm p-6 mx-4 text-center bg-white rounded-lg shadow-lg sm:p-8">
        <img
          src={checkIcon}
          alt="Purchase Success Icon"
          className="w-12 h-12 mb-4 sm:w-16 sm:h-16"
        />
        <h1 className="mb-4 text-2xl font-bold text-green-600 sm:text-4xl">
          Thank You!
        </h1>
        <p className="mb-6 text-base text-gray-700 sm:mb-8 sm:text-lg">
          You have successfully purchased an item
        </p>
        <button
          onClick={closeModal}
          className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded hover:bg-green-700 sm:text-base"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ThankYou;
