import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchCategories = createAsyncThunk(
  "category/fetchCategories",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.get("/categories/all_categories", {
        withCredentials: true,
      });
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTopMerchants = createAsyncThunk(
  "home/fetchTopMerchants",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.get("/top_merchants", {
        withCredentials: true,
      });
      const { status_code, topMerchants, message } = response.data;
      if (status_code === 0) {
        return fulfillWithValue(topMerchants);
      } else {
        return rejectWithValue(message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An error occurred"
      );
    }
  }
);

export const homeReducer = createSlice({
  name: "home",
  initialState: {
    loader: false,
    categories: [],
    topMerchants: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Categories
      .addCase(fetchCategories.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchCategories.rejected, (state, { payload }) => {
        state.loader = false;
      })
      .addCase(fetchCategories.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.categories = payload.categories || [];
      })
      // Fetch Top Merchants
      .addCase(fetchTopMerchants.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchTopMerchants.rejected, (state, { payload }) => {
        state.loader = false;
      })
      .addCase(fetchTopMerchants.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.topMerchants = payload || [];
      });
  },
});

export default homeReducer.reducer;
