import { useSelector } from "react-redux";

const ProductRating = () => {
  const averageRating = useSelector((state) => state.reviews.averageRating);

  return (
    <div className="col-span-12 sm:col-span-3">
      <div className="bg-white rounded-lg">
        <div className="flex justify-between px-5 pt-5 pb-4 border-b border-borderLine">
          <p className="text-sm font-baskerVille">Products Rating</p>
        </div>
        <div className="p-5">
          <div className="pt-4">
            <div>
              <span className="text-2xl font-semibold text-blue font-baskerVille">
                {averageRating !== null ? averageRating : "0"}
              </span>
              <span className="text-sm font-medium ms-1">out of 5</span>
            </div>
            <span className="text-sm italic font-medium text-black/50">
              {averageRating !== null ? averageRating : "0"} person(s) rated
              this product!
            </span>
            <div className="pt-8">
              <div className="flex items-center gap-4 pb-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="130"
                    height="30"
                    viewBox="0 0 158 30"
                    fill="none"
                  >
                    {/* SVG path for stars */}
                    {/* <path
                      d="M13.0979 5.8541C13.6966 4.01148 16.3034 4.01148 16.9021 5.8541L17.9187 8.98278C18.1864 9.80682 18.9543 10.3647 19.8208 10.3647H23.1105C25.0479 10.3647 25.8535 12.844 24.2861 13.9828L21.6246 15.9164C20.9237 16.4257 20.6304 17.3284 20.8981 18.1525L21.9147 21.2812C22.5134 23.1238 20.4044 24.656 18.837 23.5172L16.1756 21.5836C15.4746 21.0743 14.5254 21.0743 13.8244 21.5836L11.163 23.5172C9.59559 24.656 7.48663 23.1238 8.08533 21.2812L9.1019 18.1525C9.36965 17.3284 9.07633 16.4257 8.37536 15.9164L5.71395 13.9828C4.14652 12.844 4.95207 10.3647 6.88952 10.3647H10.1792C11.0457 10.3647 11.8136 9.80682 12.0813 8.98278L13.0979 5.8541Z"
                      fill="#FFD700"
                    /> */}
                    {/* Additional stars */}
                  </svg>
                </span>
              </div>
              <p className="text-xs italic font-medium text-black/50">
                Your feedback matters to us!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductRating;
