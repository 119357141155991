import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "./Button";
import InputField from "./inputField";
import zippyLogo from "../assets/images/zippylogo.png";
import { IoIosArrowDown, IoIosMenu, IoIosClose } from "react-icons/io";
import { RiShoppingCart2Line, RiUser3Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartItems, resetCartCount } from "../store/reducers/cartReducer";
import { clearShippingAddresses } from "../store/reducers/shippingReducer";
import { logout } from "../store/reducers/authReducer";
import { searchProducts } from "../store/reducers/productReducer";

const TopBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const { cartItems } = useSelector((state) => state.cart);
  const { products } = useSelector((state) => state.product);

  const cartCount = userInfo ? cartItems.length : 0;

  const redirect_cart_page = () => {
    if (userInfo) {
      navigate("/cart");
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(fetchCartItems(userInfo.userId));
    }
  }, [dispatch, userInfo]);

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  const toggleMobileMenu = () => {
    setMobileMenuVisible((prev) => !prev);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearShippingAddresses());
    sessionStorage.clear();

    navigate("/");
  };

  const handleSearch = () => {
    if (searchTerm.trim()) {
      dispatch(searchProducts(searchTerm)).then(() => {
        navigate(`/search-results/?query=${searchTerm}`);
      });
    }
  };

  return (
    <div className="border-b border-black/10">
      <div className="flex items-center justify-between py-4 mx-4 sm:mx-10">
        <Link to="/">
          <div className="flex items-center">
            <img src={zippyLogo} alt="ZippyWorld" className="w-auto h-8" />
            <span className="text-lg font-bold text-blue ms-4 font-baskerVille">
              Marketplace.
            </span>
          </div>
        </Link>

        <div className="flex items-center sm:hidden">
          <button onClick={toggleMobileMenu} aria-label="Menu">
            {mobileMenuVisible ? (
              <IoIosClose size={30} />
            ) : (
              <IoIosMenu size={30} />
            )}
          </button>
        </div>

        <div className="hidden mx-4 md:flex gap-x-2">
          <InputField
            inputType={"text"}
            placeholder={"Search products..."}
            inputValue={searchTerm}
            inputOnChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            buttonName={"Search"}
            buttonType={"submit"}
            onClick={handleSearch}
          />{" "}
        </div>

        <div className="items-center hidden md:flex">
          <div className="relative flex items-center mr-4">
            <span className="text-blue">
              <RiUser3Line size={20} />
            </span>

            <span className="text-blue font-semibold text-[16px] mx-2">
              {userInfo
                ? `Welcome, ${
                    userInfo.username.charAt(0).toUpperCase() +
                    userInfo.username.slice(1)
                  }`
                : "Welcome, Guest"}
            </span>

            {userInfo ? (
              <div className="relative flex items-center cursor-pointer">
                <span
                  className="cursor-pointer text-blue"
                  onClick={toggleDropdown}
                >
                  <IoIosArrowDown size={20} />
                </span>

                {dropdownVisible && (
                  <div className="absolute right-0 w-48 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg top-7">
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-black hover:bg-gray-100"
                    >
                      Profile
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="block w-full px-4 py-2 text-left text-black hover:bg-gray-100"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <span
                  className="cursor-pointer text-blue"
                  onClick={toggleDropdown}
                >
                  <IoIosArrowDown size={20} />
                </span>

                {dropdownVisible && (
                  <div className="absolute right-0 w-48 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg top-7">
                    <Link
                      to="/login"
                      className="block px-4 py-2 text-black hover:bg-gray-100"
                    >
                      Login
                    </Link>
                    <Link
                      to="/signup"
                      className="block px-4 py-2 text-black hover:bg-gray-100"
                    >
                      Sign Up
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="flex items-center justify-center ml-4">
            <span
              className="flex items-center cursor-pointer text-blue"
              onClick={redirect_cart_page}
            >
              <RiShoppingCart2Line size={24} aria-hidden="true" />
              <div className="flex items-center justify-center w-4 h-4 p-2 rounded-full text-blue">
                {cartCount}
              </div>
            </span>
          </div>
        </div>
      </div>

      {mobileMenuVisible && (
        <div className="flex flex-col items-center py-4 space-y-2 sm:hidden">
          <InputField
            inputType={"text"}
            placeholder={"Search marketplace..."}
            inputValue={searchTerm}
            inputOnChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            buttonName={"Search"}
            buttonType={"submit"}
            onClick={handleSearch}
          />
          {userInfo ? (
            <>
              <Link to="/profile" className="block text-black">
                Profile
              </Link>
              <button onClick={handleLogout} className="block text-black">
                Logout
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className="block text-black">
                Login
              </Link>
              <Link to="/signup" className="block text-black">
                Sign Up
              </Link>
            </>
          )}
          <button onClick={redirect_cart_page} className="block text-black">
            Cart ({cartCount})
          </button>
        </div>
      )}
    </div>
  );
};

export default TopBar;
