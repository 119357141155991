import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { user_register, messageClear } from "../store/reducers/authReducer";
import { FadeLoader } from "react-spinners";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import toast from "react-hot-toast";
import zippylogo from "../assets/images/zippylogo.png";

const Register = () => {
  const { loader, errorMessage, successMessage } = useSelector(
    (state) => state.auth
  );

  const [state, setState] = useState({
    username: "",
    phonenumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inputHandle = (e) => {
    const { name, value } = e.target;

    if (name === "phonenumber") {
      const numericValue = value.replace(/\D/g, "");
      if (numericValue.length <= 11) {
        setState({
          ...state,
          phonenumber: numericValue,
        });
      }
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  const register = (e) => {
    e.preventDefault();
    setErrors({});

    const { username, phonenumber, email, password, confirmPassword } = {
      ...state,
      username: state.username.trim(),
      phonenumber: state.phonenumber.trim(),
      email: state.email.trim(),
      password: state.password.trim(),
      confirmPassword: state.confirmPassword.trim(),
    };

    const newErrors = {};

    if (!username) {
      newErrors.username = "Username is required!";
    }

    if (!phonenumber) {
      newErrors.phonenumber = "Enter your Zippy wallet number!";
    } else if (phonenumber.length !== 11) {
      newErrors.phonenumber = "Phone number must be exactly 11 digits!";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      newErrors.email = "Enter a valid email address!";
    }

    if (!password) {
      newErrors.password = "Password must be at least 6 characters long!";
    } else {
      const passwordRegex =
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{6,}$/;
      if (!passwordRegex.test(password)) {
        newErrors.password =
          "Password must contain at least one digit, one lowercase letter, one uppercase letter, one special character, and be at least 6 characters long!";
      }
    }

    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match!";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const registrationData = { username, phonenumber, email, password };
    dispatch(user_register(registrationData));
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
      navigate("/login");
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage, dispatch, navigate]);

  return (
    <div className="relative flex items-center justify-center min-h-screen font-nunito">
      {loader && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-white bg-opacity-75">
          <FadeLoader />
        </div>
      )}

      <div className="relative w-3/5 max-w-lg py-4 px-8 bg-white rounded-lg shadow-lg min-w-[320px]">
        <div className="flex justify-center mb-4">
          <img src={zippylogo} alt="Zippy Logo" />
        </div>
        <h2 className="mb-3 text-2xl font-bold text-center text-gray-700">
          Create Your Zippyworld Marketplace Account
        </h2>

        <p className="mb-6 text-center text-gray-500">
          Type in your details to create an account:
        </p>

        <form onSubmit={register}>
          {/* Username Input Box */}
          <div className="mb-4">
            {errors.username && (
              <p className="mb-1 text-xs text-red">{errors.username}</p>
            )}
            <input
              type="text"
              name="username"
              onChange={inputHandle}
              value={state.username}
              placeholder="Username"
              className="w-full px-4 py-3 border rounded-md focus:outline-none focus:border-blue"
            />
          </div>

          {/* ZippyWallet Number Input Box */}
          <div className="mb-4">
            {errors.phonenumber && (
              <p className="mb-1 text-xs text-red">{errors.phonenumber}</p>
            )}
            <input
              type="text"
              name="phonenumber"
              onChange={inputHandle}
              value={state.phonenumber}
              placeholder="Zippyworld Wallet number"
              className="w-full px-4 py-3 border rounded-md focus:outline-none focus:border-blue"
            />
          </div>

          {/* Email Input Box */}
          <div className="mb-4">
            {errors.email && (
              <p className="mb-1 text-xs text-red">{errors.email}</p>
            )}
            <input
              type="email"
              name="email"
              onChange={inputHandle}
              value={state.email}
              placeholder="Email Address"
              className="w-full px-4 py-3 border rounded-md focus:outline-none focus:border-blue"
            />
          </div>

          {/* Password Input Box */}
          <div className="mb-4">
            {errors.password && (
              <p className="mb-1 text-xs text-red">{errors.password}</p>
            )}
            <div className="relative flex items-center">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={inputHandle}
                value={state.password}
                placeholder="Password"
                className="w-full px-4 py-3 border rounded-md focus:outline-none focus:border-blue"
              />
              <span
                className="absolute flex items-center cursor-pointer right-3"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <FaEyeSlash color="#B3B3B3" />
                ) : (
                  <FaEye color="#B3B3B3" />
                )}
              </span>
            </div>
          </div>

          {/* Confirm Password Input Box */}
          <div className="mb-4">
            {errors.confirmPassword && (
              <p className="mb-1 text-xs text-red">{errors.confirmPassword}</p>
            )}
            <input
              type="password"
              name="confirmPassword"
              onChange={inputHandle}
              value={state.confirmPassword}
              placeholder="Confirm Password"
              className="w-full px-4 py-3 border rounded-md focus:outline-none focus:border-blue"
            />
          </div>

          <button
            disabled={loader}
            className="bg-blue rounded-[5px] py-3 px-[30px] text-white text-sm font-normal w-full text-center transform transition-transform duration-300 hover:scale-105"
          >
            Continue
          </button>
        </form>

        <p className="mt-4 text-center text-gray-600">
          Already have an account?{" "}
          <Link
            to="/login"
            className="underline transition duration-200 cursor-pointer text-blue hover:text-[#0369a1]"
          >
            Sign in
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
