import React from "react";
import { Link, useLocation } from "react-router-dom";

const AccountSideBar = () => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <aside
      className="col-span-12 mb-4 bg-white border rounded-lg md:max-w-72 border-borderLine md:col-span-3 md:mb-0"
      style={{ height: "max-content" }}
    >
      <div className="p-4">
        <h2 className="text-lg font-bold font-baskerVille">My account.</h2>
      </div>
      <nav>
        <ul className="p-2 font-nunito text-ashColor">
          <li
            className={`hover:text-black ${
              isActive("/profile") ? "text-black font-bold" : ""
            }`}
          >
            <Link to="/profile" className="block px-4 py-2">
              My Profile
            </Link>
          </li>
          <li
            className={`hover:text-black ${
              isActive("/trackorders") ? "text-black font-bold" : ""
            }`}
          >
            <Link to="/trackorders" className="block px-4 py-2">
              Track order
            </Link>
          </li>
          <li
            className={`hover:text-black ${
              isActive("/orderhistory") ? "text-black font-bold" : ""
            }`}
          >
            <Link to="/orderhistory" className="block px-4 py-2">
              Order history
            </Link>
          </li>
          <li
            className={`hover:text-black ${
              isActive("/shippingaddress") ? "text-black font-bold" : ""
            }`}
          >
            <Link to="/shippingaddress" className="block px-4 py-2">
              Shipping address
            </Link>
          </li>
          <li
            className={`hover:text-black ${
              isActive("/support") ? "text-black font-bold" : ""
            }`}
          >
            <Link to="/support" className="block px-4 py-2">
              Help and support
            </Link>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default AccountSideBar;
