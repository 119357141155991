import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ProductCard from "../components/productCard";
import TopBar from "../components/TopBar";
import { transformGoogleDriveLink } from "../utilities/transformGoogleDriveLink";
import { generateRandomColor } from "../utilities/generateRandomColor";

const FilteredProducts = () => {
  const location = useLocation();
  const { filteredProducts, loader, errorMessage } = useSelector(
    (state) => state.product
  );
  const { categoryName } = location.state || {};

  if (loader) return <div>Loading...</div>;
  if (errorMessage) return <div>Error: {errorMessage}</div>;

  const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
    if (discountPercentage > 0) {
      return originalPrice * (1 - discountPercentage / 100);
    }
    return originalPrice;
  };

  return (
    <div className="min-h-screen bg-fullbg">
      <TopBar />
      {/* Header with bold category name */}
      <h2 className="mx-4 mt-6 text-xl font-medium sm:mx-10">
        Products Featured in {categoryName || "Category"}
        {/* Category */}
      </h2>
      <div className="grid grid-cols-2 gap-3 py-5 mx-4 my-6 sm:grid-cols-3 lg:grid-cols-5 sm:mx-10">
        {filteredProducts && filteredProducts.length > 0 ? (
          filteredProducts.map((product) => {
            let productPicture = product.product_picture;

            if (typeof productPicture === "string") {
              try {
                productPicture = JSON.parse(productPicture);
              } catch (error) {
                console.error("Error parsing product_picture:", error);
                productPicture = null;
              }
            }

            const productPictureUrl = productPicture?.picture1 || "";
            const transformedUrl = productPictureUrl
              ? transformGoogleDriveLink(productPictureUrl)
              : "";

            const originalPrice = product.product_price
              ? parseFloat(product.product_price.replace(/,/g, ""))
              : 0;
            const discountPercentage = parseFloat(
              product.product_discount_percentage
            );
            const discountedPrice = calculateDiscountedPrice(
              originalPrice,
              discountPercentage
            );

            return (
              <ProductCard
                key={product.product_id}
                productId={product.product_id}
                productImage={
                  transformedUrl ||
                  `https://via.placeholder.com/640x480.png/${generateRandomColor()}?text=${
                    product.product_name
                  }`
                }
                productName={product.product_name}
                productPrice={discountedPrice}
                originalPrice={originalPrice}
                itemLeft={product.product_quantity}
              />
            );
          })
        ) : (
          <p>No products available for this category</p>
        )}
      </div>
    </div>
  );
};

export default FilteredProducts;
