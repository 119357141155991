import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

export const addShippingAddress = createAsyncThunk(
  "shipping/addShippingAddress",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { userId, ...addressData } = data;
      const response = await api.post(
        `/users/add_shipping_address/${userId}`,
        addressData,
        { withCredentials: true }
      );

      const result = response.data;

      if (result.status_code === 0) {
        return fulfillWithValue(result.addresses.billingAddress);
      } else if (result.status_code === 1) {
        return rejectWithValue(result.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data
          ? error.response.data.message
          : error.message
      );
    }
  }
);

// Fetch Shipping Addresses
export const fetchShippingAddress = createAsyncThunk(
  "shipping/fetchShippingAddress",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/users/shipping_addresses/${userId}`);
      return response.data.addresses;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data
          ? error.response.data
          : error.message
      );
    }
  }
);

export const updateShippingAddress = createAsyncThunk(
  "shipping/updateShippingAddress",
  async (
    { shippingId, userId, updatedData },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const response = await api.post(
        `/users/update_shipping_address/${shippingId}/${userId}`,
        updatedData
      );

      const result = response.data;

      if (result.status_code === 0) {
        return fulfillWithValue(result.message);
      } else if (result.status_code === 1) {
        return rejectWithValue(result.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data
          ? error.response.data.message
          : error.message
      );
    }
  }
);

// Delete Shipping Address
export const deleteShippingAddress = createAsyncThunk(
  "shipping/deleteShippingAddress",
  async ({ shippingId, userId }, { rejectWithValue }) => {
    try {
      const response = await api.delete(
        `/users/delete_shipping_address/${shippingId}/${userId}`
      );
      const { status_code, message } = response.data;

      if (status_code === 0) {
        return { success: true, message };
      } else {
        return rejectWithValue(message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data
          ? error.response.data.message
          : error.message
      );
    }
  }
);

const initialState = {
  shippingAddresses: [],
  loader: false,
  errorMessage: "",
  successMessage: "",
};

const shippingReducer = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
    clearShippingAddresses: (state) => {
      state.shippingAddresses = [];
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(addShippingAddress.pending, (state) => {
        state.loader = true;
      })
      .addCase(addShippingAddress.fulfilled, (state, action) => {
        state.loader = false;
        state.shippingAddresses.push(action.payload);
        state.successMessage = "Shipping address added successfully";
      })
      .addCase(addShippingAddress.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.payload || "Failed to add shipping address";
      })

      // Fetch Shipping Address
      .addCase(fetchShippingAddress.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchShippingAddress.fulfilled, (state, action) => {
        state.loader = false;
        state.shippingAddresses = action.payload || [];
      })
      .addCase(fetchShippingAddress.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage =
          action.payload || "Failed to fetch shipping addresses";
      })
      .addCase(updateShippingAddress.pending, (state) => {
        state.loader = true;
      })
      .addCase(updateShippingAddress.fulfilled, (state, action) => {
        state.loader = false;
        state.successMessage = action.payload;
      })
      .addCase(updateShippingAddress.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage =
          action.payload || "Failed to update shipping address";
      })

      // Delete Shipping Address
      .addCase(deleteShippingAddress.pending, (state) => {
        state.loader = true;
      })
      .addCase(deleteShippingAddress.fulfilled, (state, action) => {
        state.loader = false;
        state.shippingAddresses = action.payload;
        state.successMessage = "Shipping address deleted successfully";
      })
      .addCase(deleteShippingAddress.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage =
          action.payload.message || "Failed to delete shipping address";
      });
  },
});

export const { messageClear, clearShippingAddresses } = shippingReducer.actions;

export default shippingReducer.reducer;
