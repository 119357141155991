import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const user_register = createAsyncThunk(
  "auth/user_register",
  async (userdetails, { rejectWithValue }) => {
    try {
      const response = await api.post("/users/register_user", userdetails, {
        withCredentials: true,
      });
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const user_login = createAsyncThunk(
  "auth/user_login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.post("/users/sign_in", credentials, {
        withCredentials: true,
      });
      const { data } = response;

      if (data.status_code === 0) {
        const userInfo = {
          userId: data.user.user_id,
          username: data.user.username,
          phonenumber: data.user.phonenumber,
          email: data.user.email,
        };
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
        return { ...data, user: userInfo };
      } else {
        return rejectWithValue({ message: data.message });
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { message: "Unknown error" }
      );
    }
  }
);

const userInfoFromSession = sessionStorage.getItem("userInfo")
  ? JSON.parse(sessionStorage.getItem("userInfo"))
  : null;

export const authReducer = createSlice({
  name: "auth",
  initialState: {
    loader: false,
    userInfo: userInfoFromSession,
    errorMessage: "",
    successMessage: "",
  },
  reducers: {
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
    loginSuccess: (state, action) => {
      state.userInfo = action.payload;
      sessionStorage.setItem("userInfo", JSON.stringify(action.payload));
    },
    logout: (state) => {
      state.userInfo = null;
      sessionStorage.removeItem("userInfo");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(user_register.pending, (state) => {
        state.loader = true;
      })
      .addCase(user_register.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload?.message || "An error occurred";
      })
      .addCase(user_register.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.successMessage =
          payload?.message || "Account Successfully created";
      })
      .addCase(user_login.pending, (state) => {
        state.loader = true;
      })
      .addCase(user_login.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload?.message || "An error occurred";
      })
      .addCase(user_login.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.successMessage = "Login successful";
        state.userInfo = payload.user;
      });
  },
});

export const { messageClear, loginSuccess, logout } = authReducer.actions;
export default authReducer.reducer;
