import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchReviews = createAsyncThunk(
  "reviews/fetchReviews",
  async (productId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/reviews/fetch_review/${productId}`, {
        withCredentials: true,
      });
      if (data.status_code === 0) {
        return fulfillWithValue(data.data || []);
      }
      return rejectWithValue(data.message || "Failed to fetch reviews");
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch reviews");
    }
  }
);

export const submitReview = createAsyncThunk(
  "reviews/submitReview",
  async (reviewData, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post("/reviews/submit_review", reviewData, {
        withCredentials: true,
      });

      if (data.status_code === 0) {
        return fulfillWithValue(data.message);
      } else if (data.status_code === 1) {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to submit review");
    }
  }
);

export const fetchAverageRating = createAsyncThunk(
  "reviews/fetchAverageRating",
  async (productId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/ratings/fetch_avg_rating/${productId}`, {
        withCredentials: true,
      });

      if (data.status_code === 0) {
        if (data.data && data.data.averageRating) {
          return fulfillWithValue(data.data.averageRating);
        } else {
          return fulfillWithValue(null);
        }
      }
      return rejectWithValue(data.message || "Failed to fetch average rating");
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error fetching average rating"
      );
    }
  }
);

const reviewReducer = createSlice({
  name: "reviews",
  initialState: {
    reviews: [],
    status: "idle",
    error: "",
    submitStatus: "idle",
    submitError: "",
    submitSuccessMessage: "",
    averageRating: null,
  },
  reducers: {
    messageClear: (state) => {
      state.submitError = "";
      state.submitSuccessMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch reviews
      .addCase(fetchReviews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReviews.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.reviews = action.payload;
      })
      .addCase(fetchReviews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Submit review
      .addCase(submitReview.pending, (state) => {
        state.submitStatus = "loading";
      })
      .addCase(submitReview.fulfilled, (state, action) => {
        state.submitStatus = "succeeded";
        state.submitSuccessMessage = action.payload;
      })
      .addCase(submitReview.rejected, (state, action) => {
        state.submitStatus = "failed";
        state.submitError = action.payload;
      })
      // Fetch average rating
      .addCase(fetchAverageRating.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAverageRating.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.averageRating = action.payload;
      })
      .addCase(fetchAverageRating.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { messageClear } = reviewReducer.actions;
export default reviewReducer.reducer;
