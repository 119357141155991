import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReviews,
  submitReview,
  messageClear,
} from "../store/reducers/reviewReducer";
import WriteReview from "./writeReview";
import { formatDate } from "../utilities/formatDate";
import toast from "react-hot-toast";

const CustomerReviews = ({ productId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const {
    reviews,
    reviewStatus,
    submitStatus,
    submitSuccessMessage,
    submitError,
  } = useSelector((state) => state.reviews);

  const productDetails = useSelector((state) => state.product.productDetails);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const userId = userInfo?.userId;
  const merchantId = productDetails?.merchant_id;

  useEffect(() => {
    if (productId) {
      dispatch(fetchReviews(productId));
    }
  }, [dispatch, productId]);

  useEffect(() => {
    if (submitStatus === "succeeded" && submitSuccessMessage) {
      toast.success(submitSuccessMessage);
      dispatch(messageClear());
    } else if (submitStatus === "failed" && submitError) {
      toast.error(submitError);
      dispatch(messageClear());
    }
  }, [submitStatus, submitSuccessMessage, submitError, dispatch]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSubmit = (review) => {
    dispatch(
      submitReview({
        ...review,
        merchantId,
        productId,
        userId,
      })
    );
    closeModal();
  };

  return (
    <div className="col-span-12 sm:col-span-9">
      <div className="bg-white rounded-lg">
        <div className="flex justify-between px-5 pt-5 pb-4 border-b border-borderLine">
          <p className="text-sm font-baskerVille">Customer Reviews</p>
          <button
            onClick={openModal}
            className="text-xs font-semibold text-blue"
          >
            Write a Review
          </button>
        </div>

        <div className="p-5">
          {reviewStatus === "loading" ? (
            <p>Loading reviews...</p>
          ) : reviews?.length > 0 ? (
            reviews.map((review) => (
              <div key={review.review_id} className="mb-4">
                <div className="text-blue font-nunito ">
                  <span className="font-semibold">
                    {review.username.charAt(0).toUpperCase() +
                      review.username.slice(1)}
                    :{" "}
                  </span>
                  <span className="text-sm opacity-50">
                    {formatDate(review.created_at)}
                  </span>
                </div>
                <div className="text-sm font-medium text-blue">
                  <span className="opacity-50">Rating: </span>
                  <span>{parseFloat(review.rating).toFixed(1)}</span>
                </div>
                <p className="text-sm">{review.comment}</p>
              </div>
            ))
          ) : (
            <p>No reviews for this product yet.</p>
          )}
        </div>
      </div>

      <WriteReview
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default CustomerReviews;
