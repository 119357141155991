import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../components/TopBar";
import AccountSideBar from "../components/accountSideBar";
import InputField from "../components/inputField";
import Button from "../components/Button";
import { orderStatusHistory } from "../store/reducers/ordersReducer";
import { formatDate } from "../utilities/formatDate";

const TrackOrders = () => {
  const [orderId, setOrderId] = useState("");
  const dispatch = useDispatch();

  const {
    loader,
    orderStatusHistory: statusHistory,
    errorMessage,
  } = useSelector((state) => state.orders);

  const handleTrackOrder = () => {
    if (orderId.trim()) {
      dispatch(orderStatusHistory(orderId));
    }
  };

  return (
    <div className="min-h-screen bg-fullbg">
      <TopBar />
      <div className="grid grid-cols-1 gap-4 p-8 md:grid-cols-12">
        {/* Aside Section */}
        <AccountSideBar />

        {/* Main Content Area */}
        <main className="grid col-span-12 p-3 overflow-x-hidden overflow-y-auto bg-white border rounded-lg md:col-span-9 md:py-4 md:px-6 border-borderLine">
          <div className="mb-8">
            <h2 className="mb-6 text-lg font-bold font-baskerVille">
              Track order
            </h2>
            <p className="mb-2">Order ID:</p>
            <div className="w-1/2 gap-2 md:flex md:items-center">
              <div className="mb-2 shrink grow md:mb-0">
                <InputField
                  inputType="text"
                  placeholder="e.g. ORD-12345"
                  inputValue={orderId}
                  inputOnChange={(e) => setOrderId(e.target.value)}
                />
              </div>
              <Button
                buttonName="Track Order"
                buttonType="button"
                onClick={handleTrackOrder}
              />
            </div>
          </div>

          <div className="flex justify-between mb-6">
            <span className="font-bold font-baskerVille">Order details:</span>
          </div>

          <div className="flex gap-4">
            <div className="w-full">
              <div className="p-2">
                <p className="mb-2">Order date:</p>
                <p className="text-ashColor">
                  {statusHistory[0]?.updated_at
                    ? formatDate(statusHistory[0].updated_at)
                    : "No data available"}
                </p>
              </div>
            </div>

            <div className="w-full">
              <div className="p-2">
                <p className="mb-4">Status:</p>
                {loader ? (
                  <p>Loading...</p>
                ) : errorMessage ? (
                  <p className="text-red">{errorMessage}</p>
                ) : (
                  <div className="relative">
                    {statusHistory.map((status, index) => (
                      <div
                        key={index}
                        className="relative flex items-center mb-4"
                      >
                        {index > 0 && (
                          <svg
                            className="absolute w-2 h-full -top-[18px] left-1"
                            height="100%"
                            width="4"
                            viewBox="0 0 4 100"
                            fill="#127EC8"
                          >
                            <line
                              x1="2"
                              y1="0"
                              x2="2"
                              y2="100"
                              stroke="#127EC8"
                              strokeWidth="4"
                            />
                          </svg>
                        )}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <circle
                              cx="8"
                              cy="8"
                              r="8"
                              fill={
                                status.status === "completed"
                                  ? "#127EC8"
                                  : "#F5F5F5"
                              }
                            />
                          </svg>
                        </span>
                        <div className="ml-2 text-sm">
                          <span className="inline-block mr-1 capitalize">
                            {status.status}
                          </span>
                          <span className="text-ashColor">
                            {new Date(status.updated_at).toLocaleDateString(
                              "en-GB"
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TrackOrders;
