import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

// Fetch all cart items
export const fetchCartItems = createAsyncThunk(
  "cart/fetchCartItems",
  async (userId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/cart/list_cart_items/${userId}`, {
        withCredentials: true,
      });
      return fulfillWithValue(data.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async (item, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post("/cart/add_item_to_cart", item, {
        withCredentials: true,
      });

      if (data.status_code === 0) {
        return fulfillWithValue(data.cart_info);
      } else {
        throw new Error(data.message || "Failed to add item to cart");
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Remove item from cart
export const removeFromCart = createAsyncThunk(
  "cart/removeFromCart",
  async ({ user_id, itemId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.delete(
        `/cart/remove_item_from_cart/${itemId}`,
        {
          data: { user_id },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        return fulfillWithValue(itemId);
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update item quantity in cart
export const updateItemQuantity = createAsyncThunk(
  "cart/updateItemQuantity",
  async (
    { user_id, itemId, quantity },

    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      await api.post(
        `/cart/update_item_quantity/${itemId}`,
        { user_id, quantity },
        { withCredentials: true }
      );

      return fulfillWithValue({ itemId, quantity });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Clear all items from cart
export const clearCart = createAsyncThunk(
  "cart/clearCart",
  async (userId, { rejectWithValue, fulfillWithValue }) => {
    try {
      await api.delete(`/cart/clear_cart/${userId}`, { withCredentials: true });
      return fulfillWithValue();
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  cartItems: [],
  tempCart: [],
  subtotal: 0,
  cart_products_count: 0,
  loader: false,
  errorMessage: "",
  successMessage: "",
};

const cartReducer = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setSubtotal: (state, action) => {
      state.subtotal = action.payload;
    },
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
    setCartProductsCount: (state, action) => {
      state.cart_products_count = action.payload;
    },

    clearTempCart: (state) => {
      state.tempCart = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartItems.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.loader = false;
        state.cartItems = action.payload;
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.payload;
      })
      .addCase(addToCart.pending, (state) => {
        state.loader = true;
      })

      .addCase(addToCart.fulfilled, (state, action) => {
        state.loader = false;
        if (!state.cartItems) {
          state.cartItems = [];
        }

        const product = action.payload?.product;
        if (product) {
          state.cartItems.push(product);
          state.cart_products_count += 1;
          state.successMessage = "Item added to cart successfully";
        } else {
          state.errorMessage = "Product data is missing in the response.";
        }
      })

      .addCase(addToCart.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage =
          action.payload.message || "Error adding item to cart";
      })
      .addCase(removeFromCart.pending, (state) => {
        state.loader = true;
      })

      .addCase(removeFromCart.fulfilled, (state, action) => {
        state.loader = false;
        state.cartItems = state.cartItems.filter(
          (item) => item.cart_id !== action.payload
        );
        state.cart_products_count -= 1;
        state.successMessage = "Item removed from cart successfully!";
      })
      .addCase(removeFromCart.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.payload;
      })
      .addCase(updateItemQuantity.pending, (state) => {
        state.loader = true;
      })
      .addCase(updateItemQuantity.fulfilled, (state, action) => {
        state.loader = false;
        const { itemId, quantity } = action.payload;
        const existingItem = state.cartItems.find((item) => item.id === itemId);
        if (existingItem) {
          existingItem.quantity = quantity;
        }
        state.successMessage = "Item quantity updated successfully!";
      })
      .addCase(updateItemQuantity.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.payload;
      })
      .addCase(clearCart.pending, (state) => {
        state.loader = true;
      })
      .addCase(clearCart.fulfilled, (state) => {
        state.cartItems = [];
        state.cart_products_count = 0;
      })
      .addCase(clearCart.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.payload;
      });
  },
});

export const {
  setSubtotal,
  messageClear,
  setCartProductsCount,
  resetCartCount,
  clearTempCart,
} = cartReducer.actions;
export default cartReducer.reducer;
