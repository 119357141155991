import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchCustomersOrders = createAsyncThunk(
  "orders/fetchCustomersOrders",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/orders/customer_orders/${customerId}`);
      if (response.data.status_code === 0) {
        return response.data;
      } else {
        return rejectWithValue({
          message: response.data.message || "Failed to fetch orders",
        });
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const trackOrder = createAsyncThunk(
  "orders/trackOrder",
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/orders/order_status/${orderId}`);
      if (response.data.status_code === 0) {
        return response.data;
      } else {
        return rejectWithValue({
          message: response.data.message || "Order not found",
        });
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchOrderDetails = createAsyncThunk(
  "orders/fetchOrderDetails",
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/orders/order_details/${orderId}`);

      if (response.data.status_code === 0) {
        return response.data.order_items;
      } else {
        return rejectWithValue({
          message: response.data.message || "Order details not found",
        });
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const orderStatusHistory = createAsyncThunk(
  "orders/orderStatusHistory",
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/orders/order_status_history/${orderId}`);

      if (response.data.status_code === 0) {
        return response.data.data;
      } else {
        return rejectWithValue({
          message: response.data.message || "Order not found",
        });
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const ordersReducer = createSlice({
  name: "orders",
  initialState: {
    loader: false,
    errorMessage: "",
    customerOrders: [],
    orderDetails: [],
    orderStatus: "",
    orderStatusHistory: [],
  },
  reducers: {
    messageClear: (state) => {
      state.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomersOrders.pending, (state) => {
        state.loader = true;
      })

      .addCase(fetchCustomersOrders.fulfilled, (state, action) => {
        state.loader = false;
        state.customerOrders = action.payload.orders;
      })
      .addCase(fetchCustomersOrders.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage =
          action.payload?.message || "Failed to fetch customer orders";
      })

      .addCase(trackOrder.pending, (state) => {
        state.loader = true;
      })
      .addCase(trackOrder.fulfilled, (state, action) => {
        state.loader = false;
        state.orderStatus = action.payload.data;
      })
      .addCase(trackOrder.rejected, (state, action) => {
        state.loader = false;
      })

      .addCase(fetchOrderDetails.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchOrderDetails.fulfilled, (state, action) => {
        state.loader = false;
        state.orderDetails = action.payload;
      })
      .addCase(fetchOrderDetails.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(orderStatusHistory.pending, (state) => {
        state.loader = true;
      })
      .addCase(orderStatusHistory.fulfilled, (state, action) => {
        state.loader = false;
        state.orderStatusHistory = action.payload;
      })
      .addCase(orderStatusHistory.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage =
          action.payload?.message || "Failed to fetch order status history";
      });
  },
});

export const { messageClear } = ordersReducer.actions;
export default ordersReducer.reducer;
