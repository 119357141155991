import { Link } from "react-router-dom";

const StoreCategories = ({ storeName, storeIcon, storeLink }) => {
  return (
    <div className="">
      <Link
        to={storeLink}
        // to={{ pathname: storeLink, state: { categoryName: storeName } }}
        className="flex items-center mb-4 gap-x-4 hover:scale-95"
      >
        <span>{storeIcon}</span>
        <span className="text-sm font-medium">{storeName}</span>
      </Link>
    </div>
  );
};

export default StoreCategories;
