import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { user_login, messageClear } from "../store/reducers/authReducer";
import { useNavigate } from "react-router-dom";
import zippylogo from "../assets/images/zippylogo.png";
import toast from "react-hot-toast";
import ClipLoader from "react-spinners/ClipLoader";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loader, errorMessage, successMessage } = useSelector(
    (state) => state.auth
  );

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const [validationError, setValidationError] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const inputHandle = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    setValidationError({
      ...validationError,
      [e.target.name]: "",
    });
  };

  const validateEmail = (email) => {
    if (!email) {
      return "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      return "Please enter a valid email address.";
    }
    return "";
  };

  const validatePassword = (password) => {
    if (!password) {
      return "Password is required.";
    }
    return "";
  };

  const submit = (e) => {
    e.preventDefault();

    const emailError = validateEmail(state.email);
    const passwordError = validatePassword(state.password);

    if (emailError || passwordError) {
      setValidationError({
        email: emailError,
        password: passwordError,
      });
      return;
    }

    dispatch(user_login(state));
  };

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
      navigate("/");
    }
  }, [errorMessage, successMessage, dispatch, navigate]);

  return (
    <div className="relative flex items-center justify-center min-h-screen font-nunito">
      {/* Spinner overlay */}
      {loader && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-white bg-opacity-75">
          <ClipLoader color="#127EC8" loading={true} size={50} />
        </div>
      )}

      <div className="relative w-3/5 max-w-lg p-8 bg-white rounded-lg shadow-lg min-w-[320px]">
        <div className="flex justify-center mb-6">
          <img src={zippylogo} alt="Zippy Logo" />
        </div>
        <h2 className="mb-3 text-2xl font-bold text-center text-gray-700">
          Welcome to Zippyworld Marketplace
        </h2>

        <p className="mb-8 text-center text-gray-500">
          Log back into your zippy marketplace account:
        </p>

        <form onSubmit={submit}>
          {/* Email Input Box */}
          <div className="mb-6">
            {validationError.email && (
              <p className="mb-1 text-xs text-red">{validationError.email}</p>
            )}
            <input
              type="email"
              name="email"
              id="email"
              onChange={inputHandle}
              value={state.email}
              placeholder="Enter your Email"
              className="w-full px-4 py-3 border rounded-md focus:outline-none focus:border-blue"
            />
          </div>

          {/* Password Input Box */}
          <div className="mb-6">
            {validationError.password && (
              <p className="mb-1 text-xs text-red">
                {validationError.password}
              </p>
            )}
            {/* Flex container for input and eye icon */}
            <div className="relative flex items-center">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                onChange={inputHandle}
                value={state.password}
                placeholder="Enter your Password"
                className="w-full px-4 py-3 mb-2 border rounded-md focus:outline-none focus:border-blue"
              />
              <span
                className="absolute flex items-center cursor-pointer right-3"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <FaEyeSlash color="#B3B3B3" />
                ) : (
                  <FaEye color="#B3B3B3" />
                )}
              </span>
            </div>
          </div>

          <button
            disabled={loader}
            className="bg-blue rounded-[5px] py-3 px-[30px] text-white text-sm font-normal w-full text-center transform transition-transform duration-300 hover:scale-105"
          >
            Login
          </button>
        </form>

        <p className="mt-4 text-center text-gray-600">
          Don’t have an account?{" "}
          <span
            onClick={() => navigate("/signup")}
            className="underline transition duration-200 cursor-pointer text-blue hover:text-[#0369a1]"
          >
            Sign up
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
